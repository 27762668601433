import React, { useEffect, useState } from "react";
import SearchInput from "components/inputs/search-input/SearchInput"
import BidsOverview from "./BidsOverview"
import { Link } from "react-router-dom"
import { routes } from "constants/routes"
import { useProduct } from "providers/ProductProvider"
import ProductListItem from "pages/product/product-list/ProductListItem"
import "./Home.css";
import { Product } from "interfaces/Product";

const Home: React.FC = () => {
  const [searchText, setSearchText] = useState<string>("");
  const [productsListSlice, setProductsListSlice] = useState<Product[]>([]);
  const { searchProducts } = useProduct();

  useEffect(() => {
    setProductsListSlice(searchProducts(searchText, 10));
  }, [searchText, searchProducts]);

  return (
    <div className="home">
      <BidsOverview />
      <SearchInput searchText={searchText} placeholder="Search products..." onComplete={setSearchText} />
      <div className="products-header">
        <h4>Products</h4>
        <Link to={routes.productsList}>View all</Link>
      </div>
      <div className="products">
        {productsListSlice.map(product => (
          <React.Fragment key={product.id}>
            <ProductListItem product={product} />
          </React.Fragment>
        ))}
        {!productsListSlice.length && <p>No products found!</p>}
      </div>
    </div>
  );
}

export default Home
