import { Form, InputGroup } from 'react-bootstrap';

import { ReactComponent as SearchIcon } from "assets/icons/search.svg";

import "./SearchInput.css";

type InputProps = {
    searchText: string;
    placeholder?: string;
    onComplete: (search: string) => void;
};

const SearchInput: React.FC<InputProps> = ({ searchText, onComplete, placeholder = "" }) => {
    return (
        <InputGroup className="search-input">
            <InputGroup.Text>
                <SearchIcon />
            </InputGroup.Text>
            <Form.Control
                placeholder={placeholder}
                value={searchText}
                onChange={e => onComplete(e.currentTarget.value)}
            />
        </InputGroup>
    );
};


export default SearchInput;
