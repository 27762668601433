import React, { useCallback, useContext, useEffect, useState } from "react";
import { Bid, BidCreateDTO } from "interfaces/Bid";
import { useAuthInterceptor } from "utils/AuthInterceptorProvider";
import { omit } from "utils/array.util";
import { useSession } from "./SessionProvider";
import { AxiosError } from "axios";

const BidContext = React.createContext<{
  bids: Bid[];
  bidHistory: Omit<Bid, "status">[];
  getUserBids: () => Promise<void>;
  getUserBidHistory: () => Promise<void>;
  getBidsByProductId: (productId: string) => Promise<Bid[] | undefined>;
  placeBid: (data: BidCreateDTO) => Promise<Bid | undefined>;
  getUserBidDetail: (productId: string) => Bid | undefined;
}>({
  bids: [],
  bidHistory: [],
  getUserBids: () => { throw Error("Method not implemented") },
  getUserBidHistory: () => { throw Error("Method not implemented") },
  getBidsByProductId: () => { throw Error("Method not implemented") },
  placeBid: () => { throw Error("Method not implemented") },
  getUserBidDetail: () => { throw Error("Method not implemented") }
});

interface Props {
  children: React.ReactNode;
}

export const BidProvider: React.FC<Props> = ({ children }) => {
  const { api } = useAuthInterceptor();
  const { token } = useSession();
  const [bids, setBids] = useState<Bid[]>([]);
  const [bidHistory, setBidHistory] = useState<Omit<Bid, "status">[]>([]);

  const getUserBids = useCallback(async () => {
    try {
      if (token) {
        const { data } = await api.get<{ data: Bid[] }>(
          `/bid/get-user-bids/`
        );

        setBids(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [api, token]);

  const getUserBidHistory = useCallback(async () => {
    try {
      if (token) {
        const { data } = await api.get<{ data: Omit<Bid, "status">[] }>(
          `/bid/get-user-bids/history/`
        );

        setBidHistory(data.data);
      }
    } catch (e) {
      console.log(e);
    }
  }, [api, token]);

  const getUserBidDetail = useCallback((bidId: string) => {
    return bids.find(bid => bid.id === bidId);
  }, [bids]);

  const getBidsByProductId = useCallback(async (productId: string) => {
    try {
      const { data } = await api.get<{ data: Bid[] }>(
        `/bid/get-bids/${productId}`
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  const placeBid = useCallback(async (bidData: BidCreateDTO) => {
    try {
      const bidRequestData = omit(bidData, ["minBidAmount", "highestBidAmount"]);
      const { data } = await api.post<{ data: Bid }>(
        "/bid/create-bid",
        bidRequestData
      );

      return data.data;
    } catch (e) {
      console.log(e);

      const error = e as AxiosError;

      return Promise.reject(error?.response?.data);
    }
  }, [api]);

  useEffect(() => {
    getUserBids();
  }, [getUserBids]);

  return (<BidContext.Provider value={{ bids, bidHistory, getUserBids, getUserBidHistory, getUserBidDetail, getBidsByProductId, placeBid }}>{children}</BidContext.Provider>);
};

export function useBid() {
  const bidContext = useContext(BidContext);

  return bidContext;
}
