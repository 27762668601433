import * as Yup from "yup";

export const ProfileSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, "Name must be at least 2 characters")
    .max(50, "Name cannot exceed 50 characters"),
  mobileNumber: Yup.string().matches(
    /^\d{10}$/,
    "Phone number should have 10 digits"
  ),
});
