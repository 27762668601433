import { TotalCount } from "components/totalCount/TotalCount"
import { useBid } from "providers/BidProvider"
import { useMemo, useState } from "react"
import { BidStatus, BidType } from "interfaces/Bid"
import MyBidsList from "./MyBidsList"
import { BidTypeFilter } from "./bid-type/BidTypeFilter"
import { isPastDate } from "utils/date.util";
import "./MyBids.css";

export const MyBids: React.FC = () => {
  const { bids } = useBid();
  const [bidType, setBidType] = useState<BidType>("COMPLETED");
  const [bidStatusFilter, setBidStatusFilter] = useState<BidStatus | null>(null);

  const completedBids = useMemo(
    () => bids.filter(bid => bidStatusFilter ? bid.status === bidStatusFilter : isPastDate(bid.product?.bidEndTime)),
    [bids, bidStatusFilter]
  );

  const upcomingBids = useMemo(
    () => bids.filter(bid => !isPastDate(bid.product?.bidEndTime)),
    [bids]
  );

  function updateBidType(bidType: BidType) {
    setBidType(bidType);
  }

  function updateFilter(status: BidStatus | null) {
    setBidStatusFilter(status);
  }

  return (
    <div className="myBids">
      <BidTypeFilter bidType={bidType} updateBidType={updateBidType} />
      <TotalCount
        bidType={bidType}
        totalCount={bidType === "COMPLETED" ? completedBids.length : upcomingBids.length}
        filter={bidStatusFilter}
        updateFilter={updateFilter}
      />
      <MyBidsList
        bidType={bidType}
        filteredBids={bidType === "COMPLETED" ? completedBids : upcomingBids}
      />
    </div>
  )
}
