import { currencyFormatter } from "utils/number.util"
import { getImageSrc } from "utils/string.util"
import { Product } from "interfaces/Product"
import { format } from "date-fns";
import "./History.css";

interface HistoryItemProps {
  product: Product;
  amount: number;
  createdAt: string;
}

export const HistoryItem: React.FC<HistoryItemProps> = ({ product, amount, createdAt }) => {

  return (
    <div className="product-item">
      <div>
        <img src={getImageSrc(product.image1)} alt={product.name} />
      </div>
      <div className="product-item-detail">
        <div>
          <h5>{product.name}</h5>
        </div>
        <div className="bid-section">
          <div>
            <p className="highest-bid">
              {currencyFormatter.format(amount)}
            </p>
            <p className="highest-bid-label">
              Your Bid
            </p>
          </div>
          <p>
            {`${format(new Date(createdAt), "PP p")}`}
          </p>
        </div>
      </div>
    </div>
  )
}
