import { Image, Modal } from "react-bootstrap"
import "./InfoModal.css"

interface InfoModalProps {
  isShown: boolean
  title: string
  message: string
  buttonText: string
  onHide: () => void
}

const InfoModal: React.FC<InfoModalProps> = ({
  isShown,
  title,
  message,
  buttonText,
  onHide,
  ...props
}) => {
  return (
    <Modal
      {...props}
      show={isShown}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div>
          <Image src="/assets/icons/success.svg" alt="" style={{ width: 22 }} />
        </div>
        <h4 style={{ marginBlock: "var(--spacing-1)" }}>{title}</h4>
        <p>{message}</p>
        <button type="button" className="modal-close-button" onClick={onHide}>
          {buttonText}
        </button>
      </Modal.Body>
    </Modal>
  )
}

export default InfoModal
