import { useRef, useState } from 'react';
import "./OtpInput.css";
import { isNotNumericKey } from 'utils/input.util';

type InputProps = {
    length?: number;
    onComplete: (pin: string) => void;
    hasError: boolean;
};

const OTPInput: React.FC<InputProps> = ({ length = 4, hasError, onComplete }) => {
    const inputRef = useRef<HTMLInputElement[]>(Array(length).fill(null));
    const [OTP, setOTP] = useState<string[]>(Array(length).fill(''));

    const handleTextChange = (input: string, index: number) => {
        const newPin = [...OTP];
        newPin[index] = input;
        setOTP(newPin);

        if (input.length === 1 && index < length - 1) {
            inputRef.current[index + 1]?.focus();
        }

        if (input.length === 0 && index > 0) {
            inputRef.current[index - 1]?.focus();
        }

        onComplete(newPin.join(''));
    };

    return (
        <div className={["otp-input", hasError ? "has-error" : ""].join(" ")}>
            {Array.from({ length }, (_, index) => (
                <input
                    key={index}
                    type="text"
                    className={Boolean(OTP[index]) ? "has-value" : ""}
                    maxLength={1}
                    value={OTP[index]}
                    onChange={(e) => handleTextChange(e.target.value, index)}
                    onKeyDown={e => isNotNumericKey(e.key) && e.preventDefault()}
                    onBlur={() => onComplete(OTP.join(""))}
                    ref={(ref: HTMLInputElement) => (inputRef.current[index] = ref)}
                />
            ))}
        </div>
    );
};


export default OTPInput;
