import { currencyFormatter } from "utils/number.util";
import * as Yup from "yup";

export const PlaceBidSchema = Yup.object().shape({
  productId: Yup.string().required(),
  minBidAmount: Yup.number().required(),
  bidAmount: Yup.number()
    .positive()
    .when(["minBidAmount", "highestBidAmount"], ([minBidAmount, highestBidAmount], schema) => {
      return highestBidAmount
        ? schema.min(
          minBidAmount + 1,
          `Bid amount must be greater than ${currencyFormatter.format(minBidAmount)}`
        )
        : schema.min(
          minBidAmount,
          `Bid amount must be greater than or equal to ${currencyFormatter.format(minBidAmount)}`
        );
    })
    .required("Bid amount is required"),
});
