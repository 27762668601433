import React from "react";
import BidListItem from "../bid-list-item/BidListItem";
import { Bid, BidStatus, BidType } from "interfaces/Bid";
import { ReactComponent as UnderReviewSvg } from "assets/icons/wait.svg";
import { ReactComponent as WinSvg } from "assets/icons/win.svg";
import { ReactComponent as LoseSvg } from "assets/icons/lose.svg";
import "./MyBidsList.css";

interface MyBidsListProps {
    bidType: BidType;
    filteredBids: Bid[];
}

function getBidStatusText(bidStatus: BidStatus) {
    switch (bidStatus) {
        case "WON": return (<div className="result won">
            <WinSvg />
            <p>You Won</p>
        </div>);
        case "LOST": return (<div className="result lost">
            <LoseSvg />
            <p>You Lose</p>
        </div>);
        case "PENDING": return (<div className="result pending">
            <UnderReviewSvg />
            <p>Under Review</p>
        </div>);
        default: return null;
    }
}

const MyBidsList: React.FC<MyBidsListProps> = ({ bidType, filteredBids }) => {
    return (
        <div>
            {filteredBids.map(bid => <React.Fragment key={bid.id}>
                <BidListItem
                    bidId={bid.id}
                    product={bid.product}
                    bidAmount={bid.bidAmount}
                    bidStatus={getBidStatusText(bid.status)}
                    bidType={bidType}
                />
            </React.Fragment>)}
        </div>
    )
}

export default MyBidsList;
