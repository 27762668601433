import "./Help.css";

const Help: React.FC = () => {
    return (
        <article className="policy">
            Page under devlopment
        </article>
    );
}

export default Help;
