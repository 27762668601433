import { Modal, Form } from "react-bootstrap"
import { BidStatus } from "interfaces/Bid"
import "./FilterModal.css";
import { useEffect } from "react";

interface FilterModalProps {
  isShown: boolean
  filter: BidStatus | null;
  onClose: () => void
  setFilter: (value: BidStatus | null) => void
}

export const FilterModal: React.FC<FilterModalProps> = ({
  isShown,
  onClose,
  filter,
  setFilter,
  ...props
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.currentTarget.value) {
      setFilter(null);
    }

    setFilter(e.currentTarget.value as BidStatus | null);
    onClose();
  }

  return (
    <Modal
      className="filterModal"
      {...props}
      show={isShown}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={onClose}
      backdrop={true}
    >
      <Modal.Body className="text-center">
        <Form>
          <Form.Check
            className="filters"
            type="radio"
            label="All"
            name="filter"
            value=""
            checked={!filter}
            onChange={handleChange}
          />
          <Form.Check
            className="filters"
            type="radio"
            label="Won"
            name="filter"
            value="WON"
            checked={filter === "WON"}
            onChange={handleChange}
          />
          <Form.Check
            className="filters"
            type="radio"
            label="Lose"
            name="filter"
            value="LOST"
            checked={filter === "LOST"}
            onChange={handleChange}
          />
        </Form>
      </Modal.Body>
    </Modal>
  )
}
