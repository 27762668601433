import { isPast } from "date-fns";
import { parseJSON, format } from "date-fns/fp";

export function formatDate(dateStr: string, fmt: string = "d MMMM, yyyy") {
    if (!dateStr) {
        return "";
    }

    return format(fmt, parseJSON(dateStr));
}

export function isPastDate(dateStr: string) {
    if (!dateStr) {
        return true;
    }

    return isPast(dateStr);
}
