import React, { useEffect, useState } from "react";
import { useProduct } from "providers/ProductProvider";
import ProductListItem from "./ProductListItem";
import SearchInput from "components/inputs/search-input/SearchInput";
import { Product } from "interfaces/Product";
import "./Products.css";

const Products: React.FC = () => {
  const { searchProducts } = useProduct();
  const [searchText, setSearchText] = useState<string>("");
  const [productsList, setProductsList] = useState<Product[]>([]);

  useEffect(() => {
    setProductsList(searchProducts(searchText));
  }, [searchText, searchProducts]);

  return (
    <div className="products">
      <SearchInput searchText={searchText} placeholder="Search products..." onComplete={setSearchText} />
      {productsList.map(product => (
        <React.Fragment key={product.id}>
          <ProductListItem product={product} />
        </React.Fragment>
      ))}
      {!productsList.length && <p>No products found!</p>}
    </div >
  )
}

export default Products
