import React, { useEffect, useState } from "react"
import { Image } from "react-bootstrap"
import { useParams } from "react-router-dom";
import Carousel from "react-bootstrap/Carousel"
import { useProduct } from "providers/ProductProvider"
import { Product } from "interfaces/Product"
import { useBid } from "providers/BidProvider"
import { Bid } from "interfaces/Bid"
import { useSession } from "providers/SessionProvider"
import PlaceBid from "pages/bid/place-bid/PlaceBid"
import ProductTimer from "../product-timer/ProductTimer"
import "./ProductResult.css";

const ProductResult = () => {
  const { getProductDetail } = useProduct();
  const { getBidsByProductId } = useBid();
  const { productId } = useParams<{ productId: string }>();
  const [product, setProduct] = useState<Product>();
  const [productBids, setProductBids] = useState<Bid[]>([]);
  const [userBid, setUserBid] = useState<Bid>();
  const [productImages, setProductImages] = useState<string[]>([]);
  const { user } = useSession();

  useEffect(() => {
    if (!productId) {
      return;
    }

    const productDetail = getProductDetail(productId);
    getBidsByProductId(productId).then(bids => {
      if (bids) {
        setProductBids(bids);
      }
    }).catch(ex => console.log(ex));

    setProduct(productDetail);

    if (productDetail) {
      setProductImages([productDetail.image1, productDetail.image2, productDetail.image3].filter(image => typeof image === "string") as string[]);
    }
  }, [productId, getBidsByProductId, getProductDetail]);

  useEffect(() => {
    const userBid = productBids.find(bid => bid.userId === user.id);

    setUserBid(userBid);
  }, [user, productBids]);

  if (!product) {
    return <p>Product not found !</p>
  }

  return (
    <div className="product-result">
      {productImages.length
        ? (<Carousel interval={null}>
          {productImages.map(image => (<Carousel.Item key={image}>
            <Image src={image} />
          </Carousel.Item>))}
        </Carousel>)
        : (<p className="text-center">No Images Available</p>)
      }

      {/* product info */}
      <div className="info">
        <h4>{product.name}</h4>

        {/* details */}
        <div className="details">
          {product.details.map((detail, idx) => {
            return (
              <React.Fragment key={idx}>
                <p>{detail}</p>
                {idx !== product.details.length - 1 && (<div className="divider"></div>)}
              </React.Fragment>
            )
          })}
        </div>

        {/* desc */}
        <div className="desc">{product.description}</div>
        <ProductTimer product={product} userBid={userBid} totalBids={productBids.length} />

        <PlaceBid userBid={userBid} />
      </div>
    </div>
  )
}

export default ProductResult;
