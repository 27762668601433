import React, { useCallback, useContext } from "react";
import { User, UserEditDTO } from "interfaces/User";
import { useAuthInterceptor } from "utils/AuthInterceptorProvider";

const UserContext = React.createContext<{
  updateUser: (userData: UserEditDTO) => Promise<User | undefined>;
}>({
  updateUser: () => { throw Error("Method not implemented") }
});

interface Props {
  children: React.ReactNode;
}

export const UserProvider: React.FC<Props> = ({ children }) => {
  const { api } = useAuthInterceptor();

  const updateUser = useCallback(async (userData: UserEditDTO) => {
    try {
      const formData = new FormData();

      formData.append("name", userData.name);
      formData.append("mobileNumber", userData.mobileNumber);

      if (userData.profileImage) {
        formData.append("profileImage", userData.profileImage);
      }

      const { data } = await api.put<{ data: User }>(
        `/user/profile`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          }
        }
      );

      return data.data;
    } catch (e) {
      console.log(e);
    }
  }, [api]);

  return (<UserContext.Provider value={{ updateUser }}>{children}</UserContext.Provider>);
};

export function useUser() {
  const userContext = useContext(UserContext);

  return userContext;
}
