import { router } from 'AppRoutes';
import './App.scss';
import { RouterProvider } from 'react-router-dom';
import { ProductProvider } from 'providers/ProductProvider';
import { BidProvider } from 'providers/BidProvider';
import { UserProvider } from 'providers/UserProvider';

function App() {
  return (
    <div className="app">
      <UserProvider>
        <ProductProvider>
          <BidProvider>
            <RouterProvider router={router} />
          </BidProvider>
        </ProductProvider>
      </UserProvider>
    </div>
  );
}

export default App;
