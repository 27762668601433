import { BidType } from "interfaces/Bid";
import { ReactComponent as Gavel } from "assets/icons/gavel.svg"
import { ReactComponent as TimerSvg } from "assets/icons/timer.svg"
import "./BidTypeFilter.css";

interface BidTypeFilterProps {
  bidType: BidType;
  updateBidType: (bidType: BidType) => void;
}

export const BidTypeFilter: React.FC<BidTypeFilterProps> = ({ bidType, updateBidType }) => {
  const handleBtnClick = (buttonId: BidType) => {
    updateBidType(buttonId)
  }

  return (
    <div className="bid-type">
      {/* pastBid */}
      <button
        onClick={() => handleBtnClick("COMPLETED")}
        className={bidType === "COMPLETED" ? "active" : ""}
      >
        <Gavel />
        <p>Past Bids</p>
      </button>
      {/* futureBid */}
      <button
        onClick={() => handleBtnClick("PENDING")}
        className={bidType === "PENDING" ? "active" : ""}
      >
        <TimerSvg />
        <p>Upcoming Bids</p>
      </button>
    </div>
  )
}
