export function omit<T extends {}, K extends keyof T>(
  obj: T,
  keys: K[]
): Omit<T, K> {
  return Object.fromEntries(
    Object.entries(obj).filter(([key]) => !keys.includes(key as K))
  ) as Omit<T, K>;
}

export function getArraySum<T>(arr: T[], field: keyof T): number {
  const sum = arr.reduce((acc, curr) => acc + Number(curr[field]), 0);

  return sum;
}
