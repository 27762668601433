import { Image } from "react-bootstrap";
import logoImage from "assets/icons/logo.svg";
import calidigLogoImage from "assets/icons/calidig-logo.svg";
import "./Splash.css";

const Splash: React.FC = () => {
    return (
        <div className="splash">
            <div></div>
            <div>
                <Image src={logoImage} fluid />
                <h1>Auction Masters</h1>
            </div>
            <div className="footer">
                <p>Powered by <Image src={calidigLogoImage} /></p>
            </div>
        </div>
    )
}

export default Splash;
