import { Image, Modal } from "react-bootstrap"
import Caution from "../../../assets/icons/caution.svg"
import "./LogoutModal.css"

interface LogoutModalProps {
  isShown: boolean
  title: string
  message: string
  buttonText: string
  buttonText2: string
  onHide: () => void
  onLogout: () => void
}

export const LogoutModal: React.FC<LogoutModalProps> = ({
  isShown,
  title,
  message,
  buttonText,
  buttonText2,
  onHide,
  onLogout,
  ...props
}) => {
  return (
    <Modal
      {...props}
      show={isShown}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body className="text-center">
        <div>
          <Image src={Caution} alt="Caution" style={{ width: 30 }} />
        </div>
        <h4 style={{ marginBlock: "var(--spacing-1)", fontWeight: "600" }}>
          {title}
        </h4>
        <p style={{ fontWeight: "400", color: "rgba(102, 102, 102, 1)" }}>
          {message}
        </p>
        <div className="buttons">
          <button
            type="button"
            className="modal-close-button-1"
            onClick={onHide}
          >
            {buttonText}
          </button>
          <button
            type="button"
            className="modal-close-button-2"
            onClick={onLogout}
          >
            {buttonText2}
          </button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
