import axios, { AxiosInstance } from "axios";
import { createContext, useContext, useRef } from "react";
import { clearSessionData, getSessionData } from "services/session.service";

const AuthInterceptorContext = createContext<{ api: AxiosInstance }>({
  api: axios,
});

interface Props {
  children: React.ReactNode;
}

export const AuthInterceptorProvider: React.FC<Props> = ({ children }) => {
  const instance = useRef(axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
  }));
  const api = instance.current;

  api.interceptors.request.use(async (config) => {
    const accessToken = getSessionData("token");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response && error.response.status === 401) {
        clearSessionData();
        return;
      }
      return Promise.reject(error);
    }
  );

  return <AuthInterceptorContext.Provider value={{ api }}>{children}</AuthInterceptorContext.Provider>
};

export const useAuthInterceptor = () => {
  const interceptorContext = useContext(AuthInterceptorContext);

  return interceptorContext;
};
