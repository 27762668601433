import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"

import Login from "pages/login/Login"
import Error from "components/error/Error"
import HomeWrapper from "pages/home/HomeWrapper"
import Home from "pages/home/Home"
import AuthGuard from "utils/AuthGuard"
import Products from "pages/product/product-list/Products"
import Otp from "pages/otp/Otp"
import { Profile } from "pages/profile/Profile"
import { MyBids } from "pages/bid/myBids/MyBids"
import ProductResult from "pages/product/product-result/ProductResult"
import { History } from "pages/history/History"
import Policy from "pages/policy/Policy"
import Help from "pages/help/Help"
import BidResult from "pages/bid/bid-result/BidResult"

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route errorElement={<Error />}>
        <Route element={<AuthGuard isProtected={false} />}>
          <Route path="/login" element={<Login />} />
          <Route path="/otp" element={<Otp />} />
        </Route>
        <Route element={<AuthGuard isProtected />}>
          <Route element={<HomeWrapper />}>
            <Route path="/" element={<Home />} />
            <Route path="/products" element={<Products />} />
            <Route path="/bids">
              <Route path="" element={<MyBids />} />
              <Route path=":bidId" element={<BidResult />} />
              <Route path="product/:productId" element={<ProductResult />} />
            </Route>
            <Route path="/profile" element={<Profile />} />
            <Route path="/history" element={<History />} />
            <Route path="/policy" element={<Policy />} />
            <Route path="/help" element={<Help />} />
          </Route>
        </Route>
      </Route>
    </>
  )
)
