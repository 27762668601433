import { useEffect, useState } from "react";
import { useBid } from "providers/BidProvider";
import "./BidsOverview.css";

interface BidContextData {
    placedBids: number;
    wonBids: number;
    lostBids: number;
}

const BidsOverview = () => {
    const { bids } = useBid();
    const [bidsContextData, setBidsContextData] = useState<BidContextData>({ placedBids: 0, wonBids: 0, lostBids: 0 });

    useEffect(() => {
        const placedBids = bids.length;
        const wonBids = bids.filter(bid => bid.status === "WON").length;
        const lostBids = bids.filter(bid => bid.status === "LOST").length;

        setBidsContextData({
            placedBids,
            wonBids,
            lostBids
        });
    }, [bids]);

    return (
        <div className="bids-overview">
            <div>
                <p className="fw-semibold">{bidsContextData.placedBids}</p>
                <p>Placed Bid</p>
            </div>
            <div>
                <p className="fw-semibold">{bidsContextData.wonBids}</p>
                <p>Won</p>
            </div>
            <div>
                <p className="fw-semibold">{bidsContextData.lostBids}</p>
                <p>Loss</p>
            </div>
        </div>
    );
}

export default BidsOverview;
