import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { useFormik } from "formik"
import { useSession } from "providers/SessionProvider"
import { Button, Form } from "react-bootstrap"
import { OtpSchema } from "./otp.schema"
import { OtpDTO } from "interfaces/Login"
import { setSessionData } from "services/session.service"
import { routes } from "constants/routes"
import { ReactComponent as ArrowLeft } from "assets/icons/arrow-left.svg"
import { ReactComponent as OtpIcon } from "assets/icons/otp.svg"
import OTPInput from "components/inputs/otp-input/OtpInput"
import InfoModal from "components/modals/InfoModal/InfoModal"
import MessageToast from "components/toasts/MessageToast";
import "./Otp.css";

const Otp: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { getUser, login, generateOTP, responseMessage, updateResponseMessage } = useSession()
  const [isOtpSuccess, setIsOtpSuccess] = useState<boolean>(false)
  const [isOtpError, setIsOtpError] = useState<boolean>(false)
  const [isShown, setIsShown] = useState<boolean>(true)
  const { setFieldValue, ...formik } = useFormik<OtpDTO>({
    initialValues: {
      otp: "",
      mobileNumber: "",
    },
    validationSchema: OtpSchema,
    onSubmit: (values) => {
      setIsOtpError(false);

      login({
        ...values,
        mobileNumber: values.mobileNumber.split(" ")?.at(1) || ""
      })
        .then((res) => {
          setSessionData("token", res.data.token)
          setIsOtpSuccess(true)
          getUser().then(() => {
            updateResponseMessage("Logged in successfully", true);
          })
            .catch((ex) => {
              updateResponseMessage(ex.message, false);
              console.log(ex)
            })
        })
        .catch((ex) => {
          console.log(ex)
          updateResponseMessage(ex.message, false);
          setIsOtpError(true)
        })
    },
  })

  function resendOTP() {
    const [dialingCode, mobileNumber] = formik.values.mobileNumber.split(" ")
    generateOTP({ dialingCode, mobileNumber })
  }

  useEffect(() => {
    if (!location?.state?.mobileNumber) {
      return navigate(routes.login)
    }

    setFieldValue("mobileNumber", location?.state?.mobileNumber);
  }, [location, navigate, setFieldValue])

  return (
    <div className="login">
      <header>
        <button
          type="button"
          className="icon-button"
          onClick={() => navigate(routes.login)}
        >
          <ArrowLeft />
        </button>
      </header>
      <div className="my-5 pt-5">
        <OtpIcon />
      </div>
      <div className="my-5 pt-5">
        <h3>OTP verification</h3>
        <p>{`Please enter the OTP sent on ${formik.values.mobileNumber}`}</p>
      </div>
      <Form className="login-form" onSubmit={formik.handleSubmit}>
        <OTPInput
          hasError={isOtpError}
          onComplete={(pin: string) => {
            if (isOtpError) {
              setIsOtpError(false)
            }

            setFieldValue("otp", pin)
          }}
        />
        {isOtpError && <p className="error danger mt-2">Wrong OTP entered !</p>}
        <div>
          <Button type="submit" disabled={!formik.isValid}>
            Confirm
          </Button>
        </div>
        <p>
          Didn&apos;t receive code?{" "}
          <button
            type="button"
            className="link-button success"
            onClick={resendOTP}
          >
            Resend Code
          </button>
        </p>
      </Form>
      <InfoModal
        isShown={isShown}
        title="OTP Sent!"
        message="We have sent a 4 digit code for phone number verification."
        buttonText="Ok"
        onHide={() => setIsShown(false)}
      />
      <InfoModal
        isShown={isOtpSuccess}
        title="Success!"
        message="Your phone number has been verified successfully."
        buttonText="Let's Get Started"
        onHide={() => {
          setIsOtpSuccess(false)
          navigate(routes.home)
        }}
      />
      <MessageToast message={responseMessage.message} isSuccess={responseMessage.isSuccess} />
    </div>
  )
}

export default Otp
