export const routes = {
  home: "/",
  login: "/login",
  otp: "/otp",
  profile: "/profile",
  productsList: "/products",
  bidResult: (bidId: string) => `/bids/${bidId}`,
  placeBid: (productId: string) => `/bids/product/${productId}`,
  bids: "/bids",
  history: "/history",
  addProduct: "/admin/products/add",
  editProduct: (productId: string) => `/admin/products/edit/${productId}`,
  policy: "/policy",
  help: "/help",
};
