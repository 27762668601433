import { HistoryItem } from "./HistoryItem"
import "./History.css"
import React, { useEffect } from "react"
import { useBid } from "providers/BidProvider"

export const History: React.FC = () => {
  const { bidHistory, getUserBidHistory } = useBid();

  useEffect(() => {
    getUserBidHistory();
  }, [getUserBidHistory]);

  return (
    <section className="notify">
      {bidHistory.map((bid, idx) => {
        return (
          <React.Fragment key={idx}>
            <HistoryItem
              product={bid.product}
              amount={bid.bidAmount}
              createdAt={bid.bidTime}
            />
          </React.Fragment>
        )
      })}
    </section>
  )
}
