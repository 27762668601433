import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useBid } from "providers/BidProvider";
import { Image } from "react-bootstrap";
import Win from "../../../assets/icons/WinThumb.svg";
import Lose from "../../../assets/icons/LoseThumb.svg";
import Carousel from "react-bootstrap/Carousel";
import { Bid } from "interfaces/Bid";
import ProductTimer from "pages/product/product-timer/ProductTimer";
import "./BidResult.css";
import { Product } from "interfaces/Product";

const BidResult: React.FC = () => {
  const { getUserBidDetail, getBidsByProductId } = useBid();
  const { bidId } = useParams<{ bidId: string }>();
  const [product, setProduct] = useState<Product>();
  const [productBids, setProductBids] = useState<Bid[]>([]);
  const [userBid, setUserBid] = useState<Bid>();
  const [productImages, setProductImages] = useState<string[]>([]);

  useEffect(() => {
    console.log(bidId)
    if (!bidId) {
      return;
    }

    const userBidDetail = getUserBidDetail(bidId);
    const userBidProduct = userBidDetail?.product;

    console.log(userBidDetail);
    if (!userBidProduct) {
      return;
    }

    getBidsByProductId(userBidDetail.productId).then(bids => {
      if (bids) {
        setProductBids(bids);
      }
    }).catch(ex => console.log(ex));


    setUserBid(userBidDetail);
    setProduct(userBidProduct);

    if (userBidProduct) {
      setProductImages([userBidProduct.image1, userBidProduct.image2, userBidProduct.image3].filter(image => typeof image === "string") as string[]);
    }
  }, [bidId, getBidsByProductId, getUserBidDetail]);

  if (!product) {
    return <p>Bid result not found !</p>
  }

  return (
    <div className="bid-result">
      {productImages.length
        ? (<Carousel interval={null}>
          {productImages.map(image => (<Carousel.Item>
            <Image src={image} />
          </Carousel.Item>))}
        </Carousel>)
        : (<p className="text-center">No Images Available</p>)
      }

      {/* userBid info */}
      <div className="info">
        <h4>{product.name}</h4>

        {/* details */}
        <div className="details">
          {product.details.map((detail, idx) => {
            return (
              <>
                <p key={idx}>{detail}</p>
                {idx !== product.details.length - 1 && (<div className="divider"></div>)}
              </>
            )
          })}
        </div>

        {/* desc */}
        <div className="desc">{product.description}</div>
        <ProductTimer product={product} userBid={userBid} totalBids={productBids.length} />

        {/* userBid Result */}
        {userBid && (<div className="bid-result">
          {userBid.status === "WON" && (
            <div className="won">
              <Image src={Win} />
              <p>Congratulations you won the deal!</p>
            </div>
          )}
          {userBid.status === "LOST" && (
            <div className="lose">
              <Image src={Lose} />
              <p>You lose the deal!</p>
            </div>
          )}
        </div>)}
      </div>
    </div>
  )
}

export default BidResult;
