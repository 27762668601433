import { routes } from "constants/routes";
import { useNavigate, useRouteError } from "react-router-dom";

const Error = () => {
    const navigate = useNavigate();
    const error = useRouteError() as { status: number };

    return (
        <div className="container box-wrapper vh-100 text-center">
            <div className="d-flex flex-column justify-content-center align-items-center h-100">
                <p>
                    {error.status === 404 ? "The page you are looking for doesn't exist" : "Something went wrong!"}
                </p>
                <button type="button" className="btn btn-primary" onClick={() => navigate(-1)}>
                    Go back
                </button>
                <button type="button" className="btn btn-primary" onClick={() => navigate(routes.home)}>
                    Go to Home
                </button>
            </div>
        </div>
    );
}

export default Error;
