import { useCallback, useEffect, useState } from "react";
import { routes } from "constants/routes";
import { differenceInDays, intervalToDuration, parseJSON } from "date-fns";
import { Product } from "interfaces/Product";
import { useNavigate } from "react-router-dom";
import { getImageSrc } from "utils/string.util";
import { Countdown } from "interfaces/Countdown";
import { currencyFormatter } from "utils/number.util";
import { ReactComponent as Gavel } from "assets/icons/gavel.svg";
import "./ProductListItem.css";

interface ProductListItemProps {
  product: Product;
}

const ProductListItem: React.FC<ProductListItemProps> = ({ product }) => {
  const navigate = useNavigate();
  const [isExpired, setIsExpired] = useState<boolean>(false);
  const [countdown, setCountdown] = useState<Countdown>({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  function handleViewBids(productId: string) {
    navigate(routes.placeBid(productId))
  }

  const getCountdownString = useCallback(() => {
    if (isExpired) {
      return "Expired";
    }

    return Object.entries(countdown).filter(duration => Boolean(duration[1])).reduce((acc, curr) => acc + curr[1] + curr[0][0] + " : ", "").slice(0, -3);
  }, [countdown, isExpired]);

  useEffect(() => {
    const biddingEndDate = product?.bidEndTime ? parseJSON(product?.bidEndTime) : new Date();

    const countdownInterval = setInterval(() => {
      const now = new Date();

      if (now >= biddingEndDate) {
        clearInterval(countdownInterval);
        return setIsExpired(true);
      }

      const duration = intervalToDuration({ start: now, end: biddingEndDate });
      const daysLeft = differenceInDays(biddingEndDate, now);

      setCountdown(prevState => ({
        ...prevState,
        days: daysLeft,
        hours: duration.hours || 0,
        minutes: duration.minutes || 0,
        seconds: duration.seconds || 0
      }));

    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [product]);

  return (
    <div className="product-item">
      <div>
        <img src={getImageSrc(product.image1 || product.image2 || product.image3)} alt={product.name} />
      </div>
      <div className="product-item-detail">
        <div>
          <h5>{product.name}</h5>
          <p className="countdown">
            {getCountdownString()}
          </p>
        </div>
        <div className="bid-section">
          <div>
            <p className="highest-bid">
              {currencyFormatter.format(product.highestBid)}
            </p>
            <p className="highest-bid-label">
              Highest Bid
            </p>
          </div>
          <button type="button" onClick={() => handleViewBids(product.id)}>
            <Gavel />
            <span>Place Bid</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default ProductListItem
